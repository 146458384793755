.ExternalModalCss {
  .ant-modal-body {
    .ant-checkbox-group {
      label {
        width: 100%;
        margin: 0;
        font-size: 0.9375rem;
        margin-bottom: 0.25rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
