.LoginPage {
  .ant-form-item-label {
    label {
      font-size: 1rem;
      color: #45b34a;
    }
  }
  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        input {
          border-color: #45b34a;
        }
      }
      .ant-form-item-control-input-content {
        span {
          border-color: #45b34a;
        }
      }
    }
    .ant-btn {
      &:hover {
        background-color: #4ba74f;
      }
    }
  }
}
